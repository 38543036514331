<template>
  <div class="login_body">
    <div class="login-left"></div>
    <div class="login-right">
      <div class="login_top">
        <div class="l_title">
          <h1 class="l_blue2 cn_name">智慧校园系统</h1>
          <div class="l_blue2 en_name">Smart Campus System</div>
        </div>
      </div>
      <div class="login_form">
        <h3 class="l_name">登录</h3>
        <ElForm ref="form" :model="formData" :rules="rules">
          <ElFormItem prop="username">
            <div class="user_account">
              <div class="my_icon">
                <i class="el-icon-user-solid"></i>
              </div>
              <input v-model="formData.username" class="l_input" placeholder="请输入账号" />
              <div style="position: absolute;right: 10px;">
                <tool-tips-group tips-content="支持身份证、手机号、工号登录"/>
              </div>
            </div>
          </ElFormItem>
          <ElFormItem prop="password">
            <div class="password_account">
              <div class="my_icon">
                <i class="el-icon-lock"></i>
              </div>
              <el-input type="password" v-model.trim="pwd" show-password placeholder="请输入密码"
                        @keyup.enter="loginHandle" />
            </div>
          </ElFormItem>
        </ElForm>
        <div class="bt_line">
          <button class="login_btn" @click="loginHandle">登 录</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      formData: {
        username: '',
        password: ''
      },
      pwd: '',
      passwordList: [],
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: { required: true, message: '请输入密码', trigger: 'blur' }
      }
    }
  },
  methods: {
    ...mapActions('login', ['login']),
    /* 用户登录 */
    loginHandle () {
      // for (const i in this.formData) {
      //   if (!this.formData[i]) {
      //     return
      //   }
      // }
      this.formData.password = this.$md5(this.pwd)
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.passwordList.push(this.formData.password)
          if (this.formData.pwd !== this.passwordList[0]) {
            this.formData.password = this.$md5(this.pwd)
          } else {
            this.formData.password = this.passwordList[0]
          }
          this.login(this.formData).then((res) => {
            window.sessionStorage.setItem('token', res)
            this.$router.push('/home')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">

.login_body {
  display: flex;
  width: 100%;
  height: 100vh;

  .l_blue {
    color: #89b5fb;
  }

  .l_blue2 {
    color: #0060ff;
  }

  .my_icon {
    flex-shrink: 0;
    width: 46px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    margin-right: 10px;
    background: #759aec;
    border-radius: 50%;
    font-size: 20px;
    color: #fff;
  }

  input::-webkit-input-placeholder {
    color: #759aec !important;
  }

  input:-ms-input-placeholder {
    color: #759aec;
  }

  .l_input {
    height: 48px;
    line-height: 48px;
    background: #cedefa;
    outline: none;
    border: none;
    font-size: 16px;
    padding: 0 23px;
    border-radius: 30px;
  }

  .login-left {
    flex: 1;
    background-image: url('~@/assets/image/login_left_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-right {
    flex: 0 0 36%;
    min-width: 450px;
    background-image: url('~@/assets/image/login_right_bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    .login_top {
      margin-top: 15vh;

      .l_title {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;

        .cn_name {
          letter-spacing: 4px;
          background-image: linear-gradient(to right, transparent 0, #e5edfa 15%, #e5edfa 90%, transparent 0);
        }

        .en_name {
          letter-spacing: 4px;
          background-image: linear-gradient(to right, transparent 0, #e5edfa 15%, #e5edfa 90%, transparent 0);
        }
      }
    }

    .login_form {
      width: 270px;
      margin: 0 auto;
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .el-form {
        .el-form-item {

          & + .el-form-item {
            margin: 30px 0;
          }

          .el-form-item__error {
            left: 80px;
          }
        }
      }

      .l_name {
        color: #1160e2;
        font-size: 28px;
        letter-spacing: 8px;
        font-family: 'Microsoft YaHei', sans-serif;
      }

      .user_account {
        display: flex;
        align-items: center;
      }

      .password_account {
        @include flex;
        align-items: center;

        .el-input {
          .el-input__inner {
            height: 48px;
            line-height: 48px;
            background: #cedefa;
            outline: none;
            border: none;
            font-size: 16px;
            padding: 0 23px;
            border-radius: 30px;

            &::placeholder {
              color: #000;
            }
          }

          .el-input__suffix-inner {
            line-height: 48px;
          }
        }
      }

      .bt_line {
        width: 100%;
        text-align: right;

        .login_btn {
          width: calc(100% - 56px);
          height: 45px;
          background: #0060ff;
          border-radius: 30px;
          padding: 5px 20px;
          color: #fff;
          border: none;
          font-size: 18px;
          cursor: pointer;

          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }
}
</style>
